import { withOptions } from '@/assets/js/utils'
import { fetchOrderCheckouts } from '@api/order'
import { ref, watch } from '@vue/composition-api'

const cached = []

export default function useCheckoutList() {
  const invoiceListTable = ref([])

  // Table Handlers
  const tableColumns = [
    {
      text: '#ID',
      align: 'start',
      value: 'id',
    },
    { text: 'User', value: 'user', sortable: false },
    { text: 'TOTAL', value: 'total' },
    { text: 'DATE', value: 'created_at' },
    { text: 'STATUS', value: 'status' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
    },
  )
  const searchQuery = ref(cached[1] || '')
  const statusFilter = ref(cached[2] || null)
  const totalInvoiceListTable = ref(0)
  const loading = ref(false)
  const selectedTableData = ref([])

  const loadOrderCheckouts = () =>
    fetchOrderCheckouts(
      withOptions(options.value, {
        search: searchQuery.value,

        // options: options.value,
        status: statusFilter.value,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data
        invoiceListTable.value = records
        totalInvoiceListTable.value = pagination.total
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const cachable = [options, searchQuery, statusFilter]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  cache()

  watch(cachable, () => {
    const newPage = options.value.page

    // reset page if filter others (i.e. no page change)
    if (newPage >= 2 && newPage === cached[0].page) {
      options.value.page = 1
    }

    loading.value = true
    selectedTableData.value = []
    loadOrderCheckouts()
    cache()
  })

  return {
    tableColumns,
    searchQuery,
    options,
    invoiceListTable,
    statusFilter,
    totalInvoiceListTable,
    loading,
    selectedTableData,
    loadOrderCheckouts,
  }
}
