var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"invoice-list"}},[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center pb-5"},[_c('v-select',{staticClass:"invoice-list-actions me-3",attrs:{"label":"Actions","single-line":"","outlined":"","dense":"","items":_vm.actions,"hide-details":"","disabled":Boolean(!_vm.selectedTableData.length)},model:{value:(_vm.selectedAction),callback:function ($$v) {_vm.selectedAction=$$v},expression:"selectedAction"}})],1),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center pb-5"},[_c('v-text-field',{staticClass:"invoice-list-search me-3",attrs:{"single-line":"","dense":"","outlined":"","hide-details":"","placeholder":"Search Checkout ID"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-select',{staticClass:"invoice-list-status",attrs:{"items":_vm.statusOptions,"single-line":"","outlined":"","dense":"","hide-details":"","clearable":"","placeholder":"Select Status"},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1)],1),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"items":_vm.invoiceListTable,"options":_vm.options,"server-items-length":_vm.totalInvoiceListTable,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.trending",fn:function(){return [_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrendingUp)+" ")])]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'checkout-view', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.receipt_number || 'n/a')+" ")])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'channel-user-view', params: { id: item.user_id } }}},[_vm._v(" "+_vm._s(item.user.email)+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("price")(item.total))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [[_c('v-chip',{class:("v-chip-light-bg " + (_vm.statusColor(item)) + "--text font-weight-semibold"),attrs:{"small":"","color":_vm.statusColor(item)}},[_vm._v(" "+_vm._s(_vm.displayStatusText(item))+" ")]),(item.status !== 'payment_succeeded')?_c('span',[_vm._v(" - "+_vm._s(item.status))]):_vm._e()]]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatDate(item.created_at)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":{ name: 'checkout-view', params: { id: item.id } }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("View Checkout")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":{ name: 'checkout-receipt-preview', params: { id: item.id } }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiReceipt)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("View Receipt")])])],1)]}}],null,true),model:{value:(_vm.selectedTableData),callback:function ($$v) {_vm.selectedTableData=$$v},expression:"selectedTableData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }